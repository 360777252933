// General background color classes used for debugging only
@font-face {
  font-family: 'icomoon';
  src:  url('assets/icomoon.eot');
  src:  url('assets/icomoon.eot') format('embedded-opentype'),
    url('assets/icomoon.ttf') format('truetype'),
    url('assets/icomoon.woff') format('woff'),
    url('assets/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.noMargin {
  margin: 0 !important;
}
.news-icon svg{
  font-size: 30px;
}
.icon-plans {
  margin-top: -9px !important;
}

.icon-Support_Chat_Help_Fill:before {
  content: "\e900";
  color: #fff;
  font-size: 25px;
  margin-bottom: -6px;
  // margin-left: -6px;
}
.icon-plans:before {
  content: "\e901";
  color: #fff;
  font-size: 41px;
  margin-bottom: -6px;
  margin-left: -6px;
}

.start-layout .info-icon,
.news-layout .news-icon,
.start-layout .info-icon, 
.start-layout .navLinkLabel-start, 
.plans-layout .navLinkLabel-plans,
.plans-layout .icon-plans:before,
.plans-layout .icon-plans,
.chat-layout .navLinkLabel-chat,
.chat-layout .chat-icon,
.help-layout .icon-Support_Chat_Help_Fill,
.help-layout .icon-Support_Chat_Help_Fill:before,
.help-layout .navLinkLabel-help,
.more-layout .more-icon,
.more-layout .navLinkLabel-more,
.community-layout .community-icon    {
  color: #0F9AF1;
}

.bgb {
  background-color: blue;
}

.bgg {
  background-color: green;
}

.bgr {
  background-color: red;
}
.mpPlusBtn:hover {
  border-color: #36A68B !important;
}

//Intercom CSS
.intercom-messenger-frame{
  width: calc(100% - 55px) !important;
  height:100% !important;
  min-height: 100% !important;
  top: 0px !important;
  right: 0px !important;
  border-radius: 0 !important;
  z-index: 1 !important;
  margin-left: 55px !important;
}

.intercom-namespace { 
  .intercom-dfosxs {
    display:none !important;
  }
}
.intercom-inlbs7, 
.intercom-nyyg93, 
#intercom-container-body .ectoxt07 {
  margin-bottom: 50px !important;
}
.intercom-1vyf8ew {
  display: none !important;
}
.makeStyles-reactPlayer-8 div {
  border-radius: 10px;
}
.Mui-selected, 
.MuiBottomNavigationAction-iconOnly:active, 
.MuiBottomNavigationAction-label:active {
  color: #0090E1 !important;
}
.sidebar {
  width: 75px !important;
  min-width: 75px !important;

  .MuiPaper-root {
    background-color: #000;
    height: 100vh;
  }
}
.ps-sidebar-container {
  background-color: #000 !important;
}
.ps-menu-button {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
}
.makeStyles-pageHeader-6 .image {
  width: 20%;
}
.plansHeader {
  margin-bottom: 50px !important;
  margin-top: 50px !important;

}
.MuiGrid-root img, .MuiGrid-root .MuiContainer-root {
  padding: 0;
}
.noMarginRight {
  margin-right: 80px !important;
}

// .chat-layout .intercom-messenger-frame {
//   width: calc(100vw - 57px) !important;
//   height: 100% !important;
//   min-height: 100% !important;
//   margin-left: 60px !important;
  
// }

.linkList .MuiLink-root {
  padding-bottom: 15px;
}


.news-layout .MuiTypography-root{
  font-family: "Open Sans", sans-serif;
}
.news-layout .intercom-lightweight-app-launcher,
.plans-layout .intercom-lightweight-app-launcher ,
.start-layout .intercom-lightweight-app-launcher,
.more-layout .intercom-lightweight-app-launcher,
.news-layout .intercom-lightweight-app-launcher,
.help-layout .intercom-lightweight-app-launcher,
.intercom-with-namespace-52k34s
{
  display: none !important;
}
.tag-container{
  display: 'inline !important';

  p{
    text-wrap: nowrap;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
    margin-right: 20px !important;
  }
  
}


@media (max-width: 1024px) {
  .intercom-lightweight-app-launcher {
    bottom: 72px !important;
  }
   
  /*localhost*/
  .intercom-dfosxs {
    bottom: 72px !important;
  }
   
  iframe[name='intercom-notifications-frame'] {
    bottom: 124px !important;
  }

}

@media (min-width: 300px) and (max-width: 767px) {

  .news-layout h1, .news-layout h3 {
    font-size: 24px;
    // padding-left: 28px;
    // padding-right: 40px;
  }
}

@media (min-width: 768px) and (max-width:1024px){
  .pageHeader {
    padding-left: 30px !important;
  }
  .makeStyles-pageHeader-6 .image {
    width: 80%;
  }

  .plansHeader {
    font-size: 1.725rem !important;
    margin-bottom: 30px !important;
    margin-top: 30px !important;
  
  }
  .MuiGrid-item{
    margin-bottom: 0 !important;
    margin-top: 10px !important;
  }
  .MuiGrid-item img{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .MuiContainer-maxWidthLg {
    margin: 0;
  }
  .noMarginRight {
    margin-right: 0 !important;
  }
  .makeStyles-reactPlayer-9, .makeStyles-reactPlayer-8, .videoPlayerWrapper iframe  {
    width: 100% !important;
    height: 100% !important;
  }
  .sidebar .MuiPaper-root {
    height: 100%;
  }

}
@media (min-width: 200px) and (max-width: 767px) {

  .sidebar .MuiPaper-root {
    z-index: 1500 !important;
    height: 100%;
  }
}